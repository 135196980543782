import {
    ADMIN_ORDERS_GET,
    ADMIN_ORDERS_GET_ONE
} from "../../actions/admin/adminOrderActions";
import {
    ADMIN_ORDERS_GET_ONE_SET,
    ADMIN_ORDERS_GET_SET
} from "../../mutations/admin/adminOrderMutations";
import adminOrderRepository from "../../../repositories/admin/AdminOrderRepository";

const state = {
    adminOrders: {},
    adminOrder: {}
}

const getters = {
    adminOrders(state) {
        return state.adminOrders;
    },
    adminOrder(state) {
        return state.adminOrder;
    }
}

const actions = {
    [ADMIN_ORDERS_GET](context) {
        return new Promise((resolve, reject) => {
            adminOrderRepository.get()
                .then((result) => {
                    context.commit(ADMIN_ORDERS_GET_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },

    [ADMIN_ORDERS_GET_ONE](context, id) {
        return new Promise((resolve, reject) => {
            adminOrderRepository.getOne(id)
                .then((result) => {
                    context.commit(ADMIN_ORDERS_GET_ONE_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
};

const mutations = {
    [ADMIN_ORDERS_GET_SET](state, list) {
        state.adminOrders = list;
    },
    [ADMIN_ORDERS_GET_ONE_SET](state, item) {
        state.adminOrder = item;
    }
}

export default { state, actions, mutations, getters };
