import UserRepository, {create, getByRole} from "../../repositories/UserRepository";
import {USER_CREATE, USER_DELETE, USER_GET, USER_GET_BY_ROLE, USER_GET_ONE, USER_UPDATE} from "../actions/userActions";
import {USER_GET_BY_ROLE_SET, USER_GET_ONE_SET, USER_GET_SET} from "../mutations/userMutations";

const state = {
	users: [],
	user: []
}

const getters = {
	users(state) {
		return state.users;
	},
	singleUser(state) {
		return state.user;
	}
}

const actions = {
	[USER_GET](context) {
		return new Promise((resolve, reject) => {
			UserRepository.get()
				.then((result) => {
					context.commit(USER_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[USER_GET_BY_ROLE](context, roleId) {
		return new Promise((resolve, reject) => {
			UserRepository.getByRole(roleId)
				.then((result) => {
					context.commit(USER_GET_BY_ROLE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[USER_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			UserRepository.getOne(id)
				.then((result) => {
					context.commit(USER_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[USER_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			UserRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[USER_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			UserRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[USER_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			UserRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[USER_GET_SET](state, groups) {
		state.users = groups;
	},
	[USER_GET_BY_ROLE_SET](state, groups) {
		state.users = groups;
	},
	[USER_GET_ONE_SET](state, group) {
		state.user = group;
	}
}

export default { state, actions, mutations, getters };
