import {APPLICATION_SETTINGS_GET, APPLICATION_SETTINGS_UPDATE} from "../actions/applicationSettingsActions";
import {APPLICATION_SETTINGS_GET_SET} from "../mutations/applicationSettingsMutations";
import ApplicationSettingsRepository from "../../repositories/ApplicationSettingsRepository";

const state = {
	applicationSettings: []
}

const getters = {
	applicationSettings(state) {
		return state.applicationSettings;
	},
}

const actions = {
	[APPLICATION_SETTINGS_GET](context) {
		return new Promise((resolve, reject) => {
			ApplicationSettingsRepository.get()
				.then((result) => {
					context.commit(APPLICATION_SETTINGS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[APPLICATION_SETTINGS_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			ApplicationSettingsRepository.update(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[APPLICATION_SETTINGS_GET_SET](state, groups) {
		state.applicationSettings = groups;
	}
}

export default { state, actions, mutations, getters };
