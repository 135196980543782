import {MOBILE_WALL_SETTINGS_GET, MOBILE_WALL_SETTINGS_UPDATE} from "../actions/mobileWallSettingsActions";
import {MOBILE_WALL_SETTINGS_GET_SET} from "../mutations/mobileWallSettingsMutations";
import MobileWallSettingsRepository from "../../repositories/MobileWallSettingsRepository";

const state = {
    mobileWallSettings: []
}

const getters = {
    mobileWallSettings(state) {
        return state.mobileWallSettings;
    },
}

const actions = {
    [MOBILE_WALL_SETTINGS_GET](context) {
        return new Promise((resolve, reject) => {
            MobileWallSettingsRepository.get()
                .then((result) => {
                    context.commit(MOBILE_WALL_SETTINGS_GET_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [MOBILE_WALL_SETTINGS_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            MobileWallSettingsRepository.update(payload)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    }
};

const mutations = {
    [MOBILE_WALL_SETTINGS_GET_SET](state, settings) {
        state.mobileWallSettings = settings;
    }
}

export default { state, actions, mutations, getters };
