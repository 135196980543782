import {
    CUSTOMER_ORDER_MAKE_ORDER,
    CUSTOMER_ORDERS_GET,
    CUSTOMER_ORDERS_GET_ONE
} from "../../actions/customer/customerOrderActions";
import {
    CUSTOMER_ORDER_MAKE_ORDER_SET,
    CUSTOMER_ORDERS_GET_ONE_SET,
    CUSTOMER_ORDERS_GET_SET
} from "../../mutations/customer/customerOrderMutations";
import CustomerOrderRepository from "../../../repositories/customer/CustomerOrderRepository";

const state = {
    customerOrderMakeOrderSettings: {},
    customerOrders: {},
    customerOrder: {}
}

const getters = {
    customerOrderMakeOrderSettings(state) {
        return state.customerOrderMakeOrderSettings;
    },
    customerOrders(state) {
        return state.customerOrders;
    },
    customerOrder(state) {
        return state.customerOrder;
    }
}

const actions = {
    [CUSTOMER_ORDER_MAKE_ORDER](context, data) {
        return new Promise((resolve, reject) => {
            CustomerOrderRepository.makeOrder(data)
                .then((result) => {
                    context.commit(CUSTOMER_ORDER_MAKE_ORDER_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },

    [CUSTOMER_ORDERS_GET](context) {
        return new Promise((resolve, reject) => {
            CustomerOrderRepository.getOrders()
                .then((result) => {
                    context.commit(CUSTOMER_ORDERS_GET_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },

    [CUSTOMER_ORDERS_GET_ONE](context, id) {
        return new Promise((resolve, reject) => {
            CustomerOrderRepository.getOneOrder(id)
                .then((result) => {
                    context.commit(CUSTOMER_ORDERS_GET_ONE_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
};

const mutations = {
    [CUSTOMER_ORDER_MAKE_ORDER_SET](state, settings) {
        state.customerOrderMakeOrderSettings = settings;
    },
    [CUSTOMER_ORDERS_GET_SET](state, list) {
        state.customerOrders = list;
    },
    [CUSTOMER_ORDERS_GET_ONE_SET](state, item) {
        state.customerOrder = item;
    }
}

export default { state, actions, mutations, getters };
