import {WALL_SETTINGS_GET, WALL_SETTINGS_UPDATE} from "../actions/wallSettingsActions";
import {WALL_SETTINGS_GET_SET} from "../mutations/wallSettingsMutations";
import WallSettingsRepository from "../../repositories/WallSettingsRepository";

const state = {
	wallSettings: []
}

const getters = {
	wallSettings(state) {
		return state.wallSettings;
	},
}

const actions = {
	[WALL_SETTINGS_GET](context) {
		return new Promise((resolve, reject) => {
			WallSettingsRepository.get()
				.then((result) => {
					context.commit(WALL_SETTINGS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[WALL_SETTINGS_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			WallSettingsRepository.update(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[WALL_SETTINGS_GET_SET](state, settings) {
		state.wallSettings = settings;
	}
}

export default {state, actions, mutations, getters};
