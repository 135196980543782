import config from "../config";

const permissions = config.permissions;
const permissionsKey = 'permissions';

export const getPermissions = () => {
	return permissions;
}

export const getUserPermissions = () => {
	return window.localStorage.getItem(permissionsKey);
};

export const savePermissions = permissions => {
	window.localStorage.setItem(permissionsKey, permissions);
};

export const destroyPermissions = () => {
	window.localStorage.removeItem(permissionsKey);
};

export const hasPermission = permission => {
	if (!getUserPermissions()) {
		return false;
	}

	return getUserPermissions().includes(permission);
};

export const hasPermissions = permissions => {
	if (typeof permissions == 'string')
		permissions = [permissions];

	return permissions.reduce((result, permission) => {
		result &= hasPermission(permission);
		return result;
	}, true);
}

export default {getPermissions, getUserPermissions, savePermissions, destroyPermissions, hasPermission, hasPermissions};
