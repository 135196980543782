import {WALL_CREATE, WALL_DELETE, WALL_GET, WALL_GET_SORTED, WALL_GET_ONE, WALL_UPDATE} from "../actions/wallActions";
import {WALL_GET_ONE_SET, WALL_GET_SET} from "../mutations/wallMutations";
import WallRepository from "../../repositories/WallRepository";

const state = {
	walls: [],
	wall: []
}

const getters = {
	walls(state) {
		return state.walls;
	},
	wall(state) {
		return state.wall;
	},
}

const actions = {
	[WALL_GET](context) {
		return new Promise((resolve, reject) => {
			WallRepository.get()
				.then((result) => {
					context.commit(WALL_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[WALL_GET_SORTED](context, column) {
		return new Promise((resolve, reject) => {
			WallRepository.getSorted(column)
				.then((result) => {
					context.commit(WALL_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[WALL_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			WallRepository.getOne(id)
				.then((result) => {
					context.commit(WALL_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[WALL_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			WallRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[WALL_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			WallRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[WALL_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			WallRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[WALL_GET_SET](state, systems) {
		state.walls = systems;
	},
	[WALL_GET_ONE_SET](state, system) {
		state.wall = system;
	}
}

export default { state, actions, mutations, getters };
