import {DOOR_SETTINGS_GET, DOOR_SETTINGS_UPDATE} from "../actions/doorSettingsActions";
import {DOOR_SETTINGS_GET_SET} from "../mutations/doorSettingsMutations";
import DoorSettingsRepository from "../../repositories/DoorSettingsRepository";

const state = {
	doorSettings: []
}

const getters = {
	doorSettings(state) {
		return state.doorSettings;
	},
}

const actions = {
	[DOOR_SETTINGS_GET](context) {
		return new Promise((resolve, reject) => {
			DoorSettingsRepository.get()
				.then((result) => {
					context.commit(DOOR_SETTINGS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_SETTINGS_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorSettingsRepository.update(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[DOOR_SETTINGS_GET_SET](state, groups) {
		state.doorSettings = groups;
	}
}

export default { state, actions, mutations, getters };
