import RepositoryService from "../../services/RepositoryService";
const resource = 'customer/doors';

export const get = () => {
	return RepositoryService.get(`${resource}`);
}

export const getSorted = (column) => {
	return RepositoryService.getRaw(`${resource}?sort=${column}`);
}

export const getOne = (id) => {
	return RepositoryService.getOne(`${resource}`, id);
}

export const getDoorLeafDimension = (payload) => {
	return RepositoryService.getRaw(`${resource}/calc/door-leaf-dimension`, payload);
}

export const getDoorMuntinsPlace = (payload) => {
	return RepositoryService.getRaw(`${resource}/calc/door-muntins-place`, payload);
}

export default { get, getSorted, getOne, getDoorLeafDimension, getDoorMuntinsPlace }