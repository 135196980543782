import config from "../config";

const locales = config.locales;
const localeKey = "locale";

export const getLocales = () => {
	return locales;
}

export const getDefaultLocale = () => {
	return getLocales().reduce((result, element) => (element.default? element : result), {});
}

export const getLocaleBySlug = (slug) => {
	return getLocales().reduce((result, element) => (element.slug === slug? element : result), {});
}

export const getCurrentLocale = () => {
	return window.localStorage.getItem(localeKey);
}

export const getLocale = () => {
	if (hasLocale()) {
		return getCurrentLocale();
	}
	return getDefaultLocale().slug;
};

export const saveLocale = (locale) => {
	if (typeof locale === 'object') {
		locale = locale.slug;
	}
	if (!exists(locale)) {
		return false;
	}
	window.localStorage.setItem(localeKey, locale);
};

export const destroyLocale = () => {
	window.localStorage.removeItem(localeKey);
};

export const hasLocale = () => {
	return (null !== getCurrentLocale() && exists(getCurrentLocale()));
};

export const exists = (locale) => {
	return !!getLocales().map(locale => locale.slug).includes(locale);
}

export default { getLocales, getDefaultLocale, getLocaleBySlug, getCurrentLocale, getLocale, saveLocale, destroyLocale, hasLocale, exists };
