import {DOOR_CREATE, DOOR_DELETE, DOOR_GET, DOOR_GET_SORTED, DOOR_GET_ONE, DOOR_UPDATE} from "../actions/doorActions";
import {DOOR_GET_ONE_SET, DOOR_GET_SET} from "../mutations/doorMutations";
import DoorRepository from "../../repositories/DoorRepository";

const state = {
	doors: [],
	door: []
}

const getters = {
	doors(state) {
		return state.doors;
	},
	door(state) {
		return state.door;
	},
}

const actions = {
	[DOOR_GET](context) {
		return new Promise((resolve, reject) => {
			DoorRepository.get()
				.then((result) => {
					context.commit(DOOR_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_GET_SORTED](context, column) {
		return new Promise((resolve, reject) => {
			DoorRepository.getSorted(column)
				.then((result) => {
					context.commit(DOOR_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			DoorRepository.getOne(id)
				.then((result) => {
					context.commit(DOOR_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			DoorRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[DOOR_GET_SET](state, groups) {
		state.doors = groups;
	},
	[DOOR_GET_ONE_SET](state, group) {
		state.door = group;
	}
}

export default { state, actions, mutations, getters };
