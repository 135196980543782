import {
	COLOR_CREATE,
	COLOR_DELETE,
	COLOR_GET,
	COLOR_GET_BY_PALETTE,
	COLOR_GET_ONE,
	COLOR_UPDATE
} from "../actions/colorActions";
import {COLOR_GET_BY_PALETTE_SET, COLOR_GET_ONE_SET, COLOR_GET_SET} from "../mutations/colorMutations";
import ColorRepository, {getByPalette} from "../../repositories/ColorRepository";

const state = {
	colors: [],
	color: []
}

const getters = {
	colors(state) {
		return state.colors;
	},
	color(state) {
		return state.color;
	},
}

const actions = {
	[COLOR_GET](context) {
		return new Promise((resolve, reject) => {
			ColorRepository.get()
				.then((result) => {
					context.commit(COLOR_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[COLOR_GET_BY_PALETTE](context, roleId) {
		return new Promise((resolve, reject) => {
			ColorRepository.getByPalette(roleId)
				.then((result) => {
					context.commit(COLOR_GET_BY_PALETTE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[COLOR_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			ColorRepository.getOne(id)
				.then((result) => {
					context.commit(COLOR_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[COLOR_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			ColorRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[COLOR_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			ColorRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[COLOR_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			ColorRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[COLOR_GET_SET](state, groups) {
		state.colors = groups;
	},
	[COLOR_GET_BY_PALETTE_SET](state, groups) {
		state.colors = groups;
	},
	[COLOR_GET_ONE_SET](state, group) {
		state.color = group;
	}
}

export default { state, actions, mutations, getters };
