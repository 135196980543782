import RepositoryService from "../services/RepositoryService";
const resource = 'admin/cylinders';

export const get = () => {
	return RepositoryService.get(`${resource}`);
}

export const getOne = (id) => {
	return RepositoryService.getOne(`${resource}`, id);
}

export const create = (payload) => {
	return RepositoryService.create(`${resource}`, payload);
}

export const update = (id, payload) => {
	return RepositoryService.update(`${resource}`, id, payload);
}

export const deleteOne = (id) => {
	return RepositoryService.deleteOne(`${resource}`, id)
}

export default { get, getOne, create, update, deleteOne }