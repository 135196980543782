import AuthRepository from "../../repositories/AuthRepository";
import PermissionsService from "../../services/PermissionsService";
import {
	AUTH_CHECK,
	AUTH_LOGIN,
	AUTH_LOGOUT, AUTH_PASSWORD_FORGOT, AUTH_PASSWORD_RESET,
	AUTH_PROFILE_EDIT,
	AUTH_REGISTER,
	AUTH_REGISTER_CONFIRM
} from "../actions/authActions";
import {AUTH_LOGIN_SET, AUTH_LOGOUT_SET} from "../mutations/authMutations";

const state = {
	user: {},
	userRoleId: null,
	isAuthenticated: null
};

const getters = {
	user(state) {
		return state.user;
	},
	userRoleId(state) {
		return state.userRoleId;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	}
};

const actions = {
	[AUTH_CHECK](context) {
		return new Promise((resolve, reject) => {
			AuthRepository.check()
				.then((result) => {
					context.commit(AUTH_LOGIN_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					context.commit(AUTH_LOGOUT_SET);
					reject(result);
				});
		});
	},
	[AUTH_LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			AuthRepository.login(credentials)
				.then((result) => {
					context.commit(AUTH_LOGIN_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[AUTH_LOGOUT](context) {
		return new Promise((resolve, reject) => {
			AuthRepository.logout()
				.then((result) => {
					context.commit(AUTH_LOGOUT_SET);
					resolve(result);
				})
				.catch(result => {
					reject(result);
				});
		});
	},
	[AUTH_PASSWORD_FORGOT](context, data) {
		return new Promise((resolve, reject) => {
			AuthRepository.passwordForgot(data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[AUTH_PASSWORD_RESET](context, data) {
		return new Promise((resolve, reject) => {
			AuthRepository.passwordReset(data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[AUTH_REGISTER](context, data) {
		return new Promise((resolve, reject) => {
			AuthRepository.register(data)
				.then((result) => {
					resolve(result);
				})
				.catch(result => {
					reject(result);
				});
		});
	},
	[AUTH_REGISTER_CONFIRM](context, userId) {
		return new Promise((resolve, reject) => {
			AuthRepository.registerConfirm(userId)
				.then((result) => {
					resolve(result);
				})
				.catch(result => {
					reject(result);
				});
		});
	},
	[AUTH_PROFILE_EDIT](context, data) {
		return new Promise((resolve, reject) => {
			AuthRepository.profileEdit(data)
				.then((result) => {
					context.commit(AUTH_LOGIN_SET, result.data.data);
					resolve(result);
				})
				.catch(result => {
					reject(result);
				});
		});
	},
};

const mutations = {
	[AUTH_LOGIN_SET](state, user) {
		state.isAuthenticated = true;
		state.user = user;
		state.errors = {};
		state.userRoleId = user.role_id;
		PermissionsService.savePermissions(user.permissions);
	},
	[AUTH_LOGOUT_SET](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		state.userRoleId = null;
		PermissionsService.destroyPermissions();
	}
};

export default { state, actions, mutations, getters };
