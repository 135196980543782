export const permissions = [
	"acoustic.manage",
	"acoustic.preview",
	"color.manage",
	"color.preview",
	"contractor_groups.manage",
	"contractor_groups.preview",
	"cylinder.manage",
	"cylinder.preview",
	"door.manage",
	"door.preview",
	"doors.manage",
	"doors.preview",
	"door_closer.preview",
	"door_closer.manage",
	"frame.preview",
	"frame.manage",
	"door_settings.preview",
	"door_settings.manage",
	"handle.manage",
	"handle.preview",
	"hinge.manage",
	"hinge.preview",
	"files.manage",
	"files.preview",
	"roles.manage",
	"roles.preview",
	"settings.manage",
	"settings.preview",
	"staff_users.preview",
	"staff_users.manage",
	"users.manage",
	"users.preview",
	"wall.manage",
	"wall.preview",
	"mobile_wall_settings.preview",
	"mobile_wall_settings.manage",
	"customer_order.store"
]
