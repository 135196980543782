import config from "../config";
import store from "../store";
import RolesService from "./RolesService";

export const getRoutes = () => {
	return config.routes;
}

export const adminRoute = () => {
	return getRoutes().admin;
}

export const orderRoute = () => {
	return getRoutes().order;
}

export const loginRoute = () => {
	return getRoutes().login;
}

export const error403Route = () => {
	return getRoutes().error403;
}

export const error404Route = () => {
	return getRoutes().error404;
}

export const defaultRoute = () => {
	switch (store.getters.userRoleId) {
		case RolesService.adminRole():
			return adminRoute();
			break;
		case RolesService.staffRole():
			return adminRoute();
			break;
		case RolesService.customerRole():
			return orderRoute();
			break;
	}

	return '';
}

export default {
	getRoutes, adminRoute, orderRoute,
	loginRoute, error404Route, error403Route,
	defaultRoute
}