import env from "../environment";
import RouterService from "./RouterService";
import LocaleService from "./LocaleService";
import { repositoryClient, repositoryClientModule } from '../repositories/clients/ClientAxios';

export const baseURL = `${env.API_URL}/${LocaleService.getLocale()}`;

const prepareUrl = (url) => {
	if (!url) {
		return;
	}

	if ((url.indexOf('http://') === -1) && url.indexOf('https://') === -1) {
		return `${baseURL}/${url}`;
	}

	return url;
}

const cancelTokens = {};

const getCancelToken = (url) => {
	if (!url) {
		return null;
	}

	if (cancelTokens && cancelTokens[url]) {
		cancelTokens[url].cancel();
		cancelTokens[url] = null;
	}
	cancelTokens[url] = repositoryClientModule.CancelToken.source();

	return cancelTokens[url];
}

export const module = repositoryClientModule;

export const get = (url, params = null, cancelToken = null) => {
	url = prepareUrl(url);

	let queryUrl = RouterService.getQuery();
	if (url.indexOf('?') != -1) {
		queryUrl = '&' + queryUrl.substr(1)
	}

	let data = {};
	if (null !== params) {
		data.params = params;
	}
	if (null === cancelToken) {
		cancelToken = getCancelToken(url);
	}
	data.cancelToken = cancelToken.token;

	return new Promise((resolve, reject) => {
		repositoryClient.get(`${url}${queryUrl}`, data)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			})
	})
}

export const getRaw = (url, params = null, cancelToken = null) => {
	url = prepareUrl(url);

	let data = {};
	if (null !== params) {
		data.params = params;
	}
	if (null === cancelToken) {
		cancelToken = getCancelToken(url);
	}
	data.cancelToken = cancelToken.token;

	return new Promise((resolve, reject) => {
		repositoryClient.get(`${url}`, data)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			})
	})
}

export const post = (url, data = {}, useCancelToken = false) => {
	url = prepareUrl(url);

	let postConfig = {};
	if (!!useCancelToken) postConfig.cancelToken = getCancelToken(url).token;

	return new Promise((resolve, reject) => {
		repositoryClient.post(`${url}`, data, postConfig)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			})
	});
}

export const put = (url, data = {}) => {
	url = prepareUrl(url);
	return new Promise((resolve, reject) => {
		repositoryClient.put(`${url}`, data)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			})
	});
}

export const del = (url, data = {}) => {
	url = prepareUrl(url);
	return new Promise((resolve, reject) => {
		repositoryClient.delete(`${url}`, data)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			})
	});
}

export const getOne = (url, id) => {
	url += (id? '/' + id : '');
	return getRaw(url);
}

export const create = (url, data) => {
	return post(`${url}`, data);
}

export const update = (url, id, data) => {
	url += (id? '/' + id : '');
	return put(`${url}`, data);
}

export const deleteOne = (url, id) => {
	url += (id? '/' + id : '');
	return del(`${url}`);
}

export default { module, baseURL, get, getRaw, post, put, del, getOne, create, update, deleteOne };
