import {
	DOOR_CYLINDER_CREATE,
	DOOR_CYLINDER_DELETE,
	DOOR_CYLINDER_GET,
	DOOR_CYLINDER_GET_ONE,
	DOOR_CYLINDER_UPDATE
} from "../actions/doorCylinderActions";
import {DOOR_CYLINDER_GET_ONE_SET, DOOR_CYLINDER_GET_SET} from "../mutations/doorCylinderMutations";
import DoorCylinderRepository from "../../repositories/DoorCylinderRepository";

const state = {
	doorCylinders: [],
	doorCylinder: []
}

const getters = {
	doorCylinders(state) {
		return state.doorCylinders;
	},
	doorCylinder(state) {
		return state.doorCylinder;
	},
}

const actions = {
	[DOOR_CYLINDER_GET](context) {
		return new Promise((resolve, reject) => {
			DoorCylinderRepository.get()
				.then((result) => {
					context.commit(DOOR_CYLINDER_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_CYLINDER_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			DoorCylinderRepository.getOne(id)
				.then((result) => {
					context.commit(DOOR_CYLINDER_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_CYLINDER_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorCylinderRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_CYLINDER_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorCylinderRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_CYLINDER_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			DoorCylinderRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[DOOR_CYLINDER_GET_SET](state, groups) {
		state.doorCylinders = groups;
	},
	[DOOR_CYLINDER_GET_ONE_SET](state, group) {
		state.doorCylinder = group;
	}
}

export default { state, actions, mutations, getters };
