import env from "./environment"
import config from "./config"
import { polyfill } from "es6-promise";
import { createApp } from "vue";
import _ from  "lodash";
import i18n from "./i18n";
import store from "./store";
import router from "./routes"
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import './yup/yupCustomRules';
import VueKonva from "vue-konva";
import App from "./App.vue";

polyfill();

window._ = _;

const app = createApp(App);
app.config.globalProperties.$env        = env;
app.config.globalProperties.$config     = config;
app.config.globalProperties.$baseUrl    = env.BASE_URL;
app.use(i18n);
app.use(store);
app.use(router);
app.use(FloatingVue);
app.use(VueKonva);
app.mount('#app');