import { createRouter, createWebHistory } from "vue-router";
import MiddlewareController from "../middlewares/MiddlewareController.js"
import LocaleService from "../services/LocaleService";
import { setLocale } from "yup";
import store from "../store";
import i18n from "../i18n";
import routes from "./general"
import messages from "../lang";
import {LOADING_ROUTE_START, LOADING_ROUTE_STOP} from "../store/actions/loadingActions";

const router = createRouter({
	history: createWebHistory(),
	routes: routes
});

router.beforeEach((to, from, next) => {
	store.dispatch(LOADING_ROUTE_START)
		.then();

	const middlewareNext = MiddlewareController({
		to,
		from,
		next,
		store
	});
	if (!!middlewareNext) {
		return middlewareNext();
	}

	return next();
});

router.afterEach((to, from) => {
	LocaleService.saveLocale(to.params.locale);
	i18n.global.locale = LocaleService.getLocale();

	if (messages[LocaleService.getLocale()]['formValidate']['yup']) {
		setLocale(messages[LocaleService.getLocale()]['formValidate']['yup']);
	}

	store.dispatch(LOADING_ROUTE_STOP)
		.then();
});

export default router;