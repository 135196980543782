import {
	CUSTOMER_MOBILE_WALLS_DIMENSION_GET,
	CUSTOMER_MOBILE_WALLS_GET,
	CUSTOMER_MOBILE_WALLS_MODULES_HEIGHT_GET
} from "../../actions/customer/customerMobileWallActions";
import {
	CUSTOMER_MOBILE_WALL_DIMENSION_SET,
	CUSTOMER_MOBILE_WALLS_GET_SET,
	CUSTOMER_MOBILE_WALL_MODULES_HEIGHT_SET
} from "../../mutations/customer/customerMobileWallMutations";
import CustomerMobileWallsRepository from "../../../repositories/customer/CustomerMobileWallsRepository";

const state = {
	customerMobileWalls: [],
	customerMobileWallDimension: {},
	customerMobileWallModulesHeight: {}
}

const getters = {
	customerMobileWalls(state) {
		return state.customerMobileWalls;
	},
	customerMobileWallDimension(state) {
		return state.customerMobileWallDimension;
	},
	customerMobileWallModulesHeight(state) {
		return state.customerMobileWallModulesHeight;
	},
}

const actions = {
	[CUSTOMER_MOBILE_WALLS_GET](context) {
		return new Promise((resolve, reject) => {
			CustomerMobileWallsRepository.get()
				.then((result) => {
					context.commit(CUSTOMER_MOBILE_WALLS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_MOBILE_WALLS_DIMENSION_GET](context, data) {
		return new Promise((resolve, reject) => {
			CustomerMobileWallsRepository.getMobileWallDimension(data)
				.then((result) => {
					context.commit(CUSTOMER_MOBILE_WALL_DIMENSION_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_MOBILE_WALLS_MODULES_HEIGHT_GET](context, data) {
		return new Promise((resolve, reject) => {
			CustomerMobileWallsRepository.getMobileWallModulesHeight(data)
				.then((result) => {
					context.commit(CUSTOMER_MOBILE_WALL_MODULES_HEIGHT_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
};

const mutations = {
	[CUSTOMER_MOBILE_WALLS_GET_SET](state, mobileWalls) {
		state.customerMobileWalls = mobileWalls;
	},
	[CUSTOMER_MOBILE_WALL_DIMENSION_SET](state, mobileWallDimension) {
		state.customerMobileWallDimension = mobileWallDimension;
	},
	[CUSTOMER_MOBILE_WALL_MODULES_HEIGHT_SET](state, mobileWallModulesHeight) {
		state.customerMobileWallModulesHeight = mobileWallModulesHeight;
	}
}

export default { state, actions, mutations, getters };
