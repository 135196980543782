import LocaleService from "../services/LocaleService";
import RouterService from "../services/RouterService";

export const LocaleExistsMiddleware = ({to, next}) => {
	if (to.params.locale && !LocaleService.exists(to.params.locale)) {
		return RouterService.push({ name: to.name });
	}
	return next();
}

export default LocaleExistsMiddleware;