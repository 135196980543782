import {CUSTOMER_COLOR_GET, CUSTOMER_COLOR_PALETTE_GET} from "../../actions/customer/customerColorActions";
import {CUSTOMER_COLOR_GET_SET, CUSTOMER_COLOR_PALETTE_GET_SET} from "../../mutations/customer/customerColorMutations";
import CustomerColorRepository from "../../../repositories/customer/CustomerColorRepository";

const state = {
	customerColors: [],
	customerColorPalette: []
}

const getters = {
	customerColors(state) {
		return state.customerColors;
	},
	customerColorPalette(state) {
		return state.customerColorPalette;
	}
}

const actions = {
	[CUSTOMER_COLOR_GET](context) {
		return new Promise((resolve, reject) => {
			CustomerColorRepository.get()
				.then((result) => {
					context.commit(CUSTOMER_COLOR_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_COLOR_PALETTE_GET](context, palette) {
		return new Promise((resolve, reject) => {
			CustomerColorRepository.getPalette(palette)
				.then((result) => {
					context.commit(CUSTOMER_COLOR_PALETTE_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
};

const mutations = {
	[CUSTOMER_COLOR_GET_SET](state, colors) {
		state.customerColors = colors;
	},
	[CUSTOMER_COLOR_PALETTE_GET_SET](state, colors) {
		state.customerColorPalette = colors;
	}
}

export default { state, actions, mutations, getters };
