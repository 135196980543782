import {
	LOADING_REQUEST_START,
	LOADING_REQUEST_STOP,
	LOADING_ROUTE_START,
	LOADING_ROUTE_STOP
} from "../actions/loadingActions";
import {
	LOADING_REQUEST_START_SET,
	LOADING_REQUEST_STOP_SET, LOADING_ROUTE_START_SET, LOADING_ROUTE_STOP_SET,
} from "../mutations/loadingMutations";

const state = {
	loadingRoute: false,
	loadingRequest: false
};

const getters = {
	loadingRoute(state) {
		return state.loadingRoute;
	},
	loadingRequest(state) {
		return state.loadingRequest;
	},
	loading(state) {
		return (state.loadingRequest || state.loadingRoute);
	}
};

const actions = {
	[LOADING_ROUTE_START](context) {
		context.commit(LOADING_ROUTE_START_SET);
	},
	[LOADING_ROUTE_STOP](context) {
		context.commit(LOADING_ROUTE_STOP_SET);
	},
	[LOADING_REQUEST_START](context) {
		context.commit(LOADING_REQUEST_START_SET);
	},
	[LOADING_REQUEST_STOP](context) {
		context.commit(LOADING_REQUEST_STOP_SET);
	}
};

const mutations = {
	[LOADING_ROUTE_START_SET](state) {
		state.loadingRoute = true;
	},
	[LOADING_ROUTE_STOP_SET](state) {
		state.loadingRoute = false;
	},
	[LOADING_REQUEST_START_SET](state) {
		state.loadingRequest = true;
	},
	[LOADING_REQUEST_STOP_SET](state) {
		state.loadingRequest = false;
	}
};

export default { state, getters, actions, mutations };