import { createI18n } from "vue-i18n";
import messages from "../lang";
import LocaleService from "../services/LocaleService";

const i18n = createI18n({
	legacy: false,
	locale: LocaleService.getLocale(),
	fallbackLocale: LocaleService.getDefaultLocale().slug,
	messages: messages,
	silentTranslationWarn: false,
	silentFallbackWarn: false
});

export default i18n;