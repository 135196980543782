export const previewWallColors = {
	defaultColorId: 1,
	colors: [
		{
			id: 1,
			name: 'Biały',
			hex_code: '#ffffff'
		},
		{
			id: 2,
			name: 'Żółty pastel',
			hex_code: '#edd29c'
		},
		{
			id: 3,
			name: 'Beżowy pastel',
			hex_code: '#f4d4bb'
		},
		{
			id: 4,
			name: 'Pomarańczowy pastel',
			hex_code: '#ecaba7'
		},
		{
			id: 5,
			name: 'Czerwony pastel',
			hex_code: '#e78f96'
		},
		{
			id: 6,
			name: 'Fioletowy pastel',
			hex_code: '#d0b8ea'
		},
		{
			id: 7,
			name: 'Różowy pastel',
			hex_code: '#f3ccfc'
		},
		{
			id: 8,
			name: 'Turkusowy pastel',
			hex_code: '#98d8db'
		},
		{
			id: 9,
			name: 'Błękitny pastel',
			hex_code: '#c2d8f0'
		},
		{
			id: 10,
			name: 'Zielony pastel',
			hex_code: '#cff6b3'
		},
		{
			id: 11,
			name: 'Granatowy',
			hex_code: '#3e4f66'
		},
		{
			id: 12,
			name: 'Grafitowy',
			hex_code: '#595856'
		}
	]
}


