import {
	NOTIFICATION_ADD, NOTIFICATION_ADD_ERROR, NOTIFICATION_ADD_INFO,
	NOTIFICATION_ADD_OK, NOTIFICATION_ADD_WARNING, NOTIFICATION_REMOVE
} from "../actions/notificationActions";
import {NOTIFICATION_ADD_SET, NOTIFICATION_REMOVE_SET} from "../mutations/notificationMutations";
import NotificationModel from "../../models/NotificationModel";
import NotificationsService from "../../services/NotificationsService";

const prepareData = (data) => {
	if (typeof data == 'string') {
		data = {
			content: data
		}
	}

	return data;
};

const state = {
	notifications: []
};

const getters = {
	notifications(state) {
		return state.notifications;
	}
};

const actions = {
	[NOTIFICATION_ADD](context, data) {
		context.commit(NOTIFICATION_ADD_SET, new NotificationModel(data));
	},
	[NOTIFICATION_ADD_OK](context, data) {
		context.commit(NOTIFICATION_ADD_SET, new NotificationModel({
			...prepareData(data), ...{
				type: NotificationsService.getTypes()['ok']
			}
		}));
	},
	[NOTIFICATION_ADD_ERROR](context, data) {
		context.commit(NOTIFICATION_ADD_SET, new NotificationModel({
			...prepareData(data), ...{
				type: NotificationsService.getTypes()['error']
			}
		}));
	},
	[NOTIFICATION_ADD_WARNING](context, data) {
		context.commit(NOTIFICATION_ADD_SET, new NotificationModel({
			...prepareData(data), ...{
				type: NotificationsService.getTypes()['warning']
			}
		}));
	},
	[NOTIFICATION_ADD_INFO](context, data) {
		context.commit(NOTIFICATION_ADD_SET, new NotificationModel({
			...prepareData(data), ...{
				type: NotificationsService.getTypes()['info']
			}
		}));
	},
	[NOTIFICATION_REMOVE](context, data) {
		context.commit(NOTIFICATION_REMOVE_SET, data);
	}
};

const mutations = {
	[NOTIFICATION_ADD_SET](state, notification) {
		state.notifications.push(notification);
	},
	[NOTIFICATION_REMOVE_SET](state, notification) {
		state.notifications = state.notifications.filter(oldNotification => {
			return oldNotification.id !== notification.id;
		})
	}
};

export default { state, getters, actions, mutations };