import { createStore } from "vuex";
import LoadingModule from "./modules/LoadingModule";
import SanctumModule from "./modules/SanctumModule";
import NotificationsModule from "./modules/NotificationsModule";
import AuthModule from "./modules/AuthModule";
import ContractorGroupModule from "./modules/ContractorGroupModule";
import UserModule from "./modules/UserModule";
import AcousticModule from "./modules/AcousticModule";
import DoorModule from "./modules/DoorModule";
import DoorHingeModule from "./modules/DoorHingeModule";
import DoorFrameModule from "./modules/DoorFrameModule";
import DoorFillingModule from "./modules/DoorFillingModule";
import DoorCylinderModule from "./modules/DoorCylinderModule";
import DoorSettingsModule from "./modules/DoorSettingsModule";
import WallModule from "./modules/WallModule";
import WallFillingModule from "./modules/WallFillingModule";
import WallSettingsModule from "./modules/WallSettingsModule";
import MobileWallSettingsModule from "./modules/MobileWallSettingsModule";
import MobileWallFillingModule from "./modules/MobileWallFillingModule";
import HandleModule from "./modules/HandleModule";
import ColorModule from "./modules/ColorModule";
import ApplicationSettingsModule from "./modules/ApplicationSettingsModule";
import DoorCloserModule from "./modules/DoorCloserModule";
import AdminOrderModule from "./modules/admin/AdminOrderModule";
import PricesModule from "./modules/PricesModule";
// CUSTOMER
import CustomerAppSettingsModule from "./modules/customer/CustomerAppSettingsModule"
import CustomerDoorModule from "./modules/customer/CustomerDoorModule"
import CustomerColorModule from "./modules/customer/CustomerColorModule";
import CustomerMobileWallModule from "./modules/customer/CustomerMobileWallModule";
import CustomerWallModule from "./modules/customer/CustomerWallModule";
import CustomerOrderModule from "./modules/customer/CustomerOrderModule";

const store = createStore({
	modules: {
		LoadingModule,
		SanctumModule,
		NotificationsModule,
		AuthModule,
		ContractorGroupModule,
		UserModule,
		AcousticModule,
		DoorModule,
		DoorHingeModule,
		DoorFrameModule,
		DoorFillingModule,
		DoorCylinderModule,
		DoorCloserModule,
		DoorSettingsModule,
		WallModule,
		WallFillingModule,
		WallSettingsModule,
		MobileWallSettingsModule,
		MobileWallFillingModule,
		HandleModule,
		ColorModule,
		ApplicationSettingsModule,
		AdminOrderModule,
		PricesModule,
		// CUSTOMER
		CustomerAppSettingsModule,
		CustomerDoorModule,
		CustomerColorModule,
		CustomerMobileWallModule,
		CustomerWallModule,
		CustomerOrderModule
	}
});

export default store;