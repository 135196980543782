import SanctumMiddleware from "../middlewares/SanctumMiddleware";
import RouteExistsMiddleware from "../middlewares/RouteExistsMiddleware";
import LocaleExistsMiddleware from "../middlewares/LocaleExistsMiddleware";
import AuthPassedMiddleware from "../middlewares/AuthPassedMiddleware";
import errorRoutes from "./error";
import authRoutes from "./auth";
import adminRoutes from "./admin";
import orderRoutes from "./order";
import LocaleService from "../services/LocaleService";
import PermissionsMiddleware from "../middlewares/PermissionsMiddleware";

const routes = [
	{
		path: '',
		redirect: {
			name: 'admin',
			params: {
				locale: LocaleService.getLocale()
			}
		}
	},
	{
		path: `/:locale`,
		component: () => import('./../views/Index.vue'),
		meta: {
			middleware: [
				SanctumMiddleware,
				LocaleExistsMiddleware
			]
		},
		children: [
			{
				name: 'admin',
				path: '',
				component: () => import('./../views/Blank.vue'),
				meta: {
					layout: 'default',
					middleware: [
						AuthPassedMiddleware
					]
				},
				children: [
					{
						name: 'Demo',
						path: 'demo',
						component: () => import('./../views/pages/Demo.vue'),
						meta: {
							middleware: [ ]
						},
					},
					...adminRoutes
				]
			},
			{
				name: 'order',
				path: 'order',
				redirect: {
					name: 'order.product'
				},
				component: () => import('../views/Blank.vue'),
				meta: {
					layout: 'columns',
					middleware: [
						AuthPassedMiddleware,
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'customer_order.store'});
						}
					]
				},
				children: [
					...orderRoutes
				]
			},
			{
				name: 'auth',
				path: '',
				component: () => import('./../views/Blank.vue'),
				meta: {
					layout: 'clear'
				},
				children: [
					// Application routes with auth layout goes here
					...authRoutes
				]
			},
			{
				name: 'errors',
				path: '',
				component: () => import('./../views/Blank.vue'),
				meta: {
					layout: 'error'
				},
				children: [
					// Application routes with clear layout goes here
					...errorRoutes
				]
			}
		]
	},
	{
		path: '/:path(.*)*',
		component: () => import('./../views/Blank.vue'),
		meta: {
			middleware: [
				SanctumMiddleware,
				LocaleExistsMiddleware,
				RouteExistsMiddleware
			]
		}
	}
];

export default routes;