import PermissionsMiddleware from "../middlewares/PermissionsMiddleware";

const adminRoutes = [
	{
		name: 'admin.profileEdit',
		path: 'profile-edit',
		component: () => import('./../views/pages/profile/ProfileEdit.vue'),
		meta: {
			middleware: [ ]
		},
	},
	{
		name: 'admin.door',
		path: 'door',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.door.hinge',
				path: 'hinge',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.hinge.list',
						path: '',
						component: () => import('../views/pages/admin/door/door_hinge/DoorHingeList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'hinge.preview'});
								}
							]
						}
					},
					{
						name: 'admin.door.hinge.add',
						path: 'add',
						component: () => import('../views/pages/admin/door/door_hinge/DoorHingeAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'hinge.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.hinge.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/door/door_hinge/DoorHingeEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'hinge.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.door.filling',
				path: 'filling',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.filling.list',
						path: '',
						component: () => import('../views/pages/admin/door/door_filling/DoorFillingList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'doors.preview'});
								}
							]
						}
					},
					{
						name: 'admin.door.filling.add',
						path: 'add',
						component: () => import('../views/pages/admin/door/door_filling/DoorFillingAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'doors.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.filling.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/door/door_filling/DoorFillingEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'doors.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.door.cylinder',
				path: 'cylinder',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.cylinder.list',
						path: '',
						component: () => import('../views/pages/admin/door/door_cylinder/DoorCylinderList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'cylinder.preview'});
								}
							]
						}
					},
					{
						name: 'admin.door.cylinder.add',
						path: 'add',
						component: () => import('../views/pages/admin/door/door_cylinder/DoorCylinderAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'cylinder.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.cylinder.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/door/door_cylinder/DoorCylinderEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'cylinder.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.door.frame',
				path: 'frame',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.frame.list',
						path: '',
						component: () => import('../views/pages/admin/door/door_frame/DoorFrameList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'frame.preview'});
								}
							]
						}
					},
					{
						name: 'admin.door.frame.add',
						path: 'add',
						component: () => import('../views/pages/admin/door/door_frame/DoorFrameAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'frame.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.frame.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/door/door_frame/DoorFrameEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'frame.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.door.system',
				path: 'system',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.system.list',
						path: '',
						component: () => import('../views/pages/admin/door/door_system/DoorSystemList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'doors.preview'});
								}
							]
						}
					},
					{
						name: 'admin.door.system.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/door/door_system/DoorSystemEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'doors.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.door.handle',
				path: 'handle',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.handle.list',
						path: '',
						component: () => import('../views/pages/admin/door/door_handle/DoorHandleList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'handle.preview'});
								}
							]
						}
					},
					{
						name: 'admin.door.handle.add',
						path: 'add',
						component: () => import('../views/pages/admin/door/door_handle/DoorHandleAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'handle.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.handle.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/door/door_handle/DoorHandleEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'handle.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.door.closer',
				path: 'closer',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.closer.list',
						path: '',
						component: () => import('../views/pages/admin/door/door_closer/DoorCloserList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'door_closer.preview'});
								}
							]
						}
					},
					{
						name: 'admin.door.closer.add',
						path: 'add',
						component: () => import('../views/pages/admin/door/door_closer/DoorCloserAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'door_closer.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.closer.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/door/door_closer/DoorCloserEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'door_closer.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.door.settings',
				path: 'settings',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.door.settings.hole',
						path: 'hole',
						component: () => import('../views/pages/admin/door/door_settings/DoorSettingsHole.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'door_settings.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.settings.doorSeal',
						path: 'door-seal',
						component: () => import('../views/pages/admin/door/door_settings/DoorSettingsDoorSeal.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'door_settings.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.settings.fanlight',
						path: 'fanlight',
						component: () => import('../views/pages/admin/door/door_settings/DoorSettingsFanlight.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'door_settings.manage'});
								}
							]
						}
					},
					{
						name: 'admin.door.settings.overpanel',
						path: 'overpanel',
						component: () => import('../views/pages/admin/door/door_settings/DoorSettingsOverpanel.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'door_settings.manage'});
								}
							]
						}
					}
				]
			}
		]
	},
	{
		name: 'admin.contractors',
		path: 'contractor',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.contractor.list',
				path: '',
				component: () => import('../views/pages/admin/contractor/ContractorList.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'users.preview'});
						}
					]
				},
			},
			{
				name: 'admin.contractor.add',
				path: 'add',
				component: () => import('../views/pages/admin/contractor/ContractorAdd.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'users.manage'});
						}
					]
				},
			},
			{
				name: 'admin.contractor.edit',
				path: 'edit/:id',
				component: () => import('../views/pages/admin/contractor/ContractorEdit.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'users.manage'});
						}
					]
				},
			}
		]
	},
	{
		name: 'admin.contractorGroups',
		path: 'contractor-groups',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.contractorGroup.list',
				path: '',
				component: () => import('../views/pages/admin/contractor_group/ContractorGroupsList.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'contractor_groups.preview'});
						}
					]
				},
			},
			{
				name: 'admin.contractorGroup.add',
				path: 'add',
				component: () => import('../views/pages/admin/contractor_group/ContractorGroupAdd.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'contractor_groups.manage'});
						}
					]
				},
			},
			{
				name: 'admin.contractorGroup.edit',
				path: 'edit/:id',
				component: () => import('../views/pages/admin/contractor_group/ContractorGroupEdit.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'contractor_groups.manage'});
						}
					]
				},
			}
		]
	},
	{
		name: 'admin.staff',
		path: 'staff',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.staff.list',
				path: '',
				component: () => import('../views/pages/admin/staff/StaffList.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_users.preview'});
						}
					]
				},
			},
			{
				name: 'admin.staff.add',
				path: 'add',
				component: () => import('../views/pages/admin/staff/StaffAdd.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_users.manage'});
						}
					]
				},
			},
			{
				name: 'admin.staff.edit',
				path: 'edit/:id',
				component: () => import('../views/pages/admin/staff/StaffEdit.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_users.manage'});
						}
					]
				},
			}
		]
	},
	{
		name: 'admin.admin',
		path: 'admin',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.admin.list',
				path: '',
				component: () => import('../views/pages/admin/admin/AdminList.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_users.preview'});
						}
					]
				},
			},
			{
				name: 'admin.admin.add',
				path: 'add',
				component: () => import('../views/pages/admin/admin/AdminAdd.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_users.manage'});
						}
					]
				},
			},
			{
				name: 'admin.admin.edit',
				path: 'edit/:id',
				component: () => import('../views/pages/admin/admin/AdminEdit.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_users.manage'});
						}
					]
				},
			}
		]
	},
	{
		name: 'admin.acoustic',
		path: 'acoustic',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.acoustic.list',
				path: '',
				component: () => import('../views/pages/admin/acoustic/AcousticList.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'acoustic.preview'});
						}
					]
				},
			},
			{
				name: 'admin.acoustic.add',
				path: 'add',
				component: () => import('../views/pages/admin/acoustic/AcousticAdd.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'acoustic.manage'});
						}
					]
				},
			},
			{
				name: 'admin.acoustic.edit',
				path: 'edit/:id',
				component: () => import('../views/pages/admin/acoustic/AcousticEdit.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'acoustic.manage'});
						}
					]
				},
			}
		]
	},
	{
		name: 'admin.color',
		path: 'color',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.color.ral',
				path: 'ral',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.color.ral.list',
						path: '',
						component: () => import('../views/pages/admin/color/color_ral/ColorRalList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.preview'});
								}
							]
						}
					},
					{
						name: 'admin.color.ral.add',
						path: 'add',
						component: () => import('../views/pages/admin/color/color_ral/ColorRalAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.manage'});
								}
							]
						}
					},
					{
						name: 'admin.color.ral.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/color/color_ral/ColorRalEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.color.hpl',
				path: 'hpl',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.color.hpl.list',
						path: '',
						component: () => import('../views/pages/admin/color/color_hpl/ColorHplList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.preview'});
								}
							]
						}
					},
					{
						name: 'admin.color.hpl.add',
						path: 'add',
						component: () => import('../views/pages/admin/color/color_hpl/ColorHplAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.manage'});
								}
							]
						}
					},
					{
						name: 'admin.color.hpl.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/color/color_hpl/ColorHplEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.color.venner',
				path: 'venner',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.color.venner.list',
						path: '',
						component: () => import('../views/pages/admin/color/color_venner/ColorVennerList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.preview'});
								}
							]
						}
					},
					{
						name: 'admin.color.venner.add',
						path: 'add',
						component: () => import('../views/pages/admin/color/color_venner/ColorVennerAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.manage'});
								}
							]
						}
					},
					{
						name: 'admin.color.venner.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/color/color_venner/ColorVennerEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'color.manage'});
								}
							]
						}
					}
				]
			}
		]
	},
	{
		name: 'admin.wall',
		path: 'wall',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.wall.system',
				path: 'system',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.wall.system.list',
						path: '',
						component: () => import('../views/pages/admin/wall/wall_system/WallSystemList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'wall.preview'});
								}
							]
						}
					},
					{
						name: 'admin.wall.system.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/wall/wall_system/WallSystemEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'wall.manage'});
								}
							]
						}
					},
				]
			},
			{
				name: 'admin.wall.filling',
				path: 'filling',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.wall.filling.list',
						path: '',
						component: () => import('../views/pages/admin/wall/wall_filling/WallFillingList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'wall.preview'});
								}
							]
						}
					},
					{
						name: 'admin.wall.filling.add',
						path: 'add',
						component: () => import('../views/pages/admin/wall/wall_filling/WallFillingAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'wall.manage'});
								}
							]
						}
					},
					{
						name: 'admin.wall.filling.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/wall/wall_filling/WallFillingEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'wall.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.wall.settings',
				path: 'settings',
				component: () => import('../views/pages/admin/wall/wall_settings/WallSettings.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'wall.manage'});
						}
					]
				}
			}
		]
	},
	{
		name: 'admin.mobile_wall',
		path: 'mobile-wall',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.mobile_wall.handle',
				path: 'handle',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.mobile_wall.handle.list',
						path: '',
						component: () => import('../views/pages/admin/mobile_wall/mobile_wall_handle/MobileWallHandleList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'handle.preview'});
								}
							]
						}
					},
					{
						name: 'admin.mobile_wall.handle.add',
						path: 'add',
						component: () => import('../views/pages/admin/mobile_wall/mobile_wall_handle/MobileWallHandleAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'handle.manage'});
								}
							]
						}
					},
					{
						name: 'admin.mobile_wall.handle.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/mobile_wall/mobile_wall_handle/MobileWallHandleEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'handle.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.mobile_wall.filling',
				path: 'filling',
				component: () => import('../views/Blank.vue'),
				children: [
					{
						name: 'admin.mobile_wall.filling.list',
						path: '',
						component: () => import('../views/pages/admin/mobile_wall/mobile_wall_filling/MobileWallFillingList.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'mobile_wall_settings.manage'});
								}
							]
						}
					},
					{
						name: 'admin.mobile_wall.filling.add',
						path: 'add',
						component: () => import('../views/pages/admin/mobile_wall/mobile_wall_filling/MobileWallFillingAdd.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'mobile_wall_settings.manage'});
								}
							]
						}
					},
					{
						name: 'admin.mobile_wall.filling.edit',
						path: 'edit/:id',
						component: () => import('../views/pages/admin/mobile_wall/mobile_wall_filling/MobileWallFillingEdit.vue'),
						meta: {
							middleware: [
								(context) => {
									return PermissionsMiddleware({...context, permissions: 'mobile_wall_settings.manage'});
								}
							]
						}
					}
				]
			},
			{
				name: 'admin.mobile_wall.settings',
				path: 'settings',
				component: () => import('../views/pages/admin/mobile_wall/mobile_wall_settings/MobileWallSettings.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'mobile_wall_settings.manage'});
						}
					]
				}
			}
		]
	},
	{
		name: 'admin.settings',
		path: 'settings',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.settings.contact',
				path: 'contact',
				component: () => import('../views/pages/admin/application_settings/AppliactionSettingsContact.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'settings.manage'});
						}
					]
				}
			},
			{
				name: 'admin.settings.mountingHole',
				path: 'mounting-hole',
				component: () => import('../views/pages/admin/application_settings/AppliactionSettingsMountingHole.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'settings.manage'});
						}
					]
				}
			},
			{
				name: 'admin.settings.mail',
				path: 'mail',
				component: () => import('../views/pages/admin/application_settings/AppliactionSettingsMail.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'settings.manage'});
						}
					]
				}
			}
		]
	},
	{
		name: 'admin.prices',
		path: 'prices',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.prices.list',
				path: 'list',
				component: () => import('../views/pages/admin/prices/PricesList.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'price_file.preview'});
						}
					]
				},
			},
			{
				name: 'admin.prices.add',
				path: 'add',
				component: () => import('../views/pages/admin/prices/PricesAdd.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'price_file.manage'});
						}
					]
				},
			}
		]
	},
	{
		name: 'admin.orders',
		path: 'orders',
		component: () => import('../views/Blank.vue'),
		children: [
			{
				name: 'admin.orders.list',
				path: '',
				component: () => import('../views/pages/admin/orders/OrdersList.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_order.manage'});
						}
					]
				},
			},
			{
				name: 'admin.orders.preview',
				path: 'preview/:id',
				component: () => import('../views/pages/admin/orders/OrdersListPreview.vue'),
				meta: {
					middleware: [
						(context) => {
							return PermissionsMiddleware({...context, permissions: 'staff_order.manage'});
						}
					]
				},
			}
		],
	},
	{
		name: 'admin.userOrders',
		path: 'my-orders',
		component: () => import('../views/pages/user/orders/UserOrdersList.vue'),
		meta: {
			middleware: [
				(context) => {
					return PermissionsMiddleware({...context, permissions: 'customer_order.store'});
				}
			]
		}
	}
];

export default adminRoutes;
