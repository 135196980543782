import {
    MOBILE_WALL_FILLING_CREATE,
    MOBILE_WALL_FILLING_DELETE,
    MOBILE_WALL_FILLING_GET,
    MOBILE_WALL_FILLING_GET_ONE,
    MOBILE_WALL_FILLING_UPDATE
} from "../actions/mobileWallFillingActions";
import {MOBILE_WALL_FILLING_GET_ONE_SET, MOBILE_WALL_FILLING_GET_SET} from "../mutations/mobileWallFillingMutations";
import MobileWallFillingRepository from "../../repositories/MobileWallFillingRepository";

const state = {
    mobileWallFillings: [],
    mobileWallFilling: []
}

const getters = {
    mobileWallFillings(state) {
        return state.mobileWallFillings;
    },
    mobileWallFilling(state) {
        return state.mobileWallFilling;
    },
}

const actions = {
    [MOBILE_WALL_FILLING_GET](context) {
        return new Promise((resolve, reject) => {
            MobileWallFillingRepository.get()
                .then((result) => {
                    context.commit(MOBILE_WALL_FILLING_GET_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [MOBILE_WALL_FILLING_GET_ONE](context, id) {
        return new Promise((resolve, reject) => {
            MobileWallFillingRepository.getOne(id)
                .then((result) => {
                    context.commit(MOBILE_WALL_FILLING_GET_ONE_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [MOBILE_WALL_FILLING_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            MobileWallFillingRepository.create(payload)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [MOBILE_WALL_FILLING_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            MobileWallFillingRepository.update(payload.id, payload.data)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [MOBILE_WALL_FILLING_DELETE](context, id) {
        return new Promise((resolve, reject) => {
            MobileWallFillingRepository.deleteOne(id)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    }
};

const mutations = {
    [MOBILE_WALL_FILLING_GET_SET](state, fillings) {
        state.mobileWallFillings = fillings;
    },
    [MOBILE_WALL_FILLING_GET_ONE_SET](state, filling) {
        state.mobileWallFilling = filling;
    }
}

export default {state, actions, mutations, getters};
