import RepositoryService from "../../services/RepositoryService";
const resource = 'order';

export const get = () => {
	return RepositoryService.get(`${resource}`);
}

export const makeOrder = (payload) => {
	return RepositoryService.post(`customer/orders`, payload, true);
}

export const getOrders = () => {
	return RepositoryService.get(`customer/orders`);
}

export const getOneOrder = (id) => {
	return RepositoryService.getOne(`customer/orders`, id);
}

export default { get, makeOrder, getOrders, getOneOrder }