import PricesRepository from "../../repositories/PricesRepository";
import {PRICES_GET, PRICES_CREATE, PRICES_GET_TYPES, PRICES_IMPORT_AGAIN} from "../actions/pricesActions";
import {PRICES_GET_SET, PRICES_GET_TYPES_SET} from "../mutations/pricesMutations";

const state = {
	prices: [],
	pricesTypes: []
}

const getters = {
	prices(state) {
		return state.prices;
	},
	pricesTypes(state) {
		return state.pricesTypes;
	}
}

const actions = {
	[PRICES_GET](context) {
		return new Promise((resolve, reject) => {
			PricesRepository.get()
				.then((result) => {
					context.commit(PRICES_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[PRICES_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			PricesRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[PRICES_GET_TYPES](context) {
		return new Promise((resolve, reject) => {
			PricesRepository.getTypes()
				.then((result) => {
					context.commit(PRICES_GET_TYPES_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[PRICES_IMPORT_AGAIN](context, id) {
		return new Promise((resolve, reject) => {
			PricesRepository.importAgain(id)
				.then((result) => {
					context.commit(PRICES_GET_TYPES_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		})
	}
};

const mutations = {
	[PRICES_GET_SET](state, prices) {
		state.prices = prices;
	},
	[PRICES_GET_TYPES_SET](state, types) {
		state.pricesTypes = types;
	},
}

export default { state, actions, mutations, getters };
