import {
    HANDLE_CREATE,
    HANDLE_DELETE,
    HANDLE_GET,
    HANDLE_GET_ONE,
	HANDLE_GET_BY_TYPE,
    HANDLE_UPDATE
} from "../actions/handleActions";
import {HANDLE_GET_ONE_SET, HANDLE_GET_SET, HANDLE_GET_BY_TYPE_SET} from "../mutations/handleMutations";
import HandleRepository from "../../repositories/HandleRepository";

const state = {
    handles: [],
    handle: [],
	handlesByType: []
}

const getters = {
    handles(state) {
        return state.handles;
    },
    handle(state) {
        return state.handle;
    },
	handlesByType(state) {
		return state.handlesByType;
	},
}

const actions = {
    [HANDLE_GET](context) {
        return new Promise((resolve, reject) => {
            HandleRepository.get()
                .then((result) => {
                    context.commit(HANDLE_GET_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [HANDLE_GET_ONE](context, id) {
        return new Promise((resolve, reject) => {
            HandleRepository.getOne(id)
                .then((result) => {
                    context.commit(HANDLE_GET_ONE_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [HANDLE_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            HandleRepository.create(payload)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [HANDLE_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            HandleRepository.update(payload.id, payload.data)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [HANDLE_DELETE](context, id) {
        return new Promise((resolve, reject) => {
            HandleRepository.deleteOne(id)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
	[HANDLE_GET_BY_TYPE](context, type) {
		return new Promise((resolve, reject) => {
			HandleRepository.getByType(type)
				.then((result) => {
					context.commit(HANDLE_GET_BY_TYPE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[HANDLE_GET_SET](state, handles) {
		state.handles = handles;
	},
	[HANDLE_GET_ONE_SET](state, handle) {
		state.handle = handle;
	},
	[HANDLE_GET_BY_TYPE_SET](state, handles) {
		state.handlesByType = handles;
	},
}

export default { state, actions, mutations, getters };
