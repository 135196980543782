import {
    DOOR_CLOSER_CREATE,
    DOOR_CLOSER_DELETE,
    DOOR_CLOSER_GET,
    DOOR_CLOSER_GET_ONE,
    DOOR_CLOSER_UPDATE
} from "../actions/doorCloserActions";
import {DOOR_CLOSER_GET_ONE_SET, DOOR_CLOSER_GET_SET} from "../mutations/doorCloserMutations";
import DoorCloserRepository from "../../repositories/DoorCloserRepository";

const state = {
    doorClosers: [],
    doorCloser: []
}

const getters = {
    doorClosers(state) {
        return state.doorClosers;
    },
    doorCloser(state) {
        return state.doorCloser;
    },
}

const actions = {
    [DOOR_CLOSER_GET](context) {
        return new Promise((resolve, reject) => {
            DoorCloserRepository.get()
                .then((result) => {
                    context.commit(DOOR_CLOSER_GET_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [DOOR_CLOSER_GET_ONE](context, id) {
        return new Promise((resolve, reject) => {
            DoorCloserRepository.getOne(id)
                .then((result) => {
                    context.commit(DOOR_CLOSER_GET_ONE_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [DOOR_CLOSER_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            DoorCloserRepository.create(payload)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [DOOR_CLOSER_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            DoorCloserRepository.update(payload.id, payload.data)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [DOOR_CLOSER_DELETE](context, id) {
        return new Promise((resolve, reject) => {
            DoorCloserRepository.deleteOne(id)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    }
};

const mutations = {
    [DOOR_CLOSER_GET_SET](state, groups) {
        state.doorClosers = groups;
    },
    [DOOR_CLOSER_GET_ONE_SET](state, group) {
        state.doorCloser = group;
    }
}

export default { state, actions, mutations, getters };
