import SanctumRepository from "../../repositories/SanctumRepository";
import {SANCTUM_CSRF} from "../actions/sanctumActions";

const state = { }

const getters = { }

const actions = {
	[SANCTUM_CSRF](context) {
		return new Promise((resolve, reject) => {
			SanctumRepository.csrfToken()
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
};

const mutations = { }

export default { state, actions, mutations, getters };
