import axios from "axios";
import store from "../../store";
import i18n from "../../i18n";
import {
	LOADING_REQUEST_START,
	LOADING_REQUEST_STOP,
} from "../../store/actions/loadingActions";
import {NOTIFICATION_ADD_ERROR, NOTIFICATION_ADD_OK} from "../../store/actions/notificationActions";

export const repositoryClientModule = axios;

export const repositoryClient = repositoryClientModule.create({
	withCredentials: true,
	headers: {
		'X-Requested-With': 'XMLHttpRequest'
	}
});

repositoryClient.interceptors.request.use((config) => {
	store.dispatch(LOADING_REQUEST_START).then();

	return config;
}, (error) => {
	return Promise.reject(error);
});

repositoryClient.interceptors.response.use((response) => {
	store.dispatch(LOADING_REQUEST_STOP).then();

	return response;
}, (error) => {
	if (axios.isCancel(error)) {
		return Promise.reject(error);
	}
	store.dispatch(LOADING_REQUEST_STOP).then();

	if (typeof error.response === "undefined") {
		return Promise.reject(error);
	}

	if (403 === error.response.status) {
		store.dispatch(NOTIFICATION_ADD_ERROR, i18n.global.t('request.error.403')).then();
	}

	if (400 === error.response.status) {
		store.dispatch(NOTIFICATION_ADD_ERROR, i18n.global.t('request.error.400')).then();
	}

	if (503 === error.response.status) {
		store.dispatch(NOTIFICATION_ADD_ERROR, i18n.global.t('request.error.503')).then();

		setTimeout(function(){
			window.location.reload(true);
		}, 3000)
	}

	if (500 === error.response.status) {
		store.dispatch(NOTIFICATION_ADD_ERROR, i18n.global.t('request.error.500')).then();
	}

	return Promise.reject(error);
});

export default { repositoryClientModule, repositoryClient };