import store from "../store";
import { AUTH_CHECK } from "../store/actions/authActions";
import LocaleService from "../services/LocaleService";
import RouterService from "../services/RouterService";
import RoutesService from "../services/RoutesService";

export const AuthPassedMiddleware = ({to, next}) => {
	if (store.getters.isAuthenticated) {
		return next();
	}

	store.dispatch(AUTH_CHECK)
		.then((response) => {
			return next();
		})
		.catch((response) => {
			const locale = to.params.locale? to.params.locale : LocaleService.getLocale();
			return RouterService.push({ name: RoutesService.loginRoute(), params: { locale: locale } });
		});
}

export default AuthPassedMiddleware;