import {
    WALL_FILLING_CREATE,
    WALL_FILLING_DELETE,
    WALL_FILLING_GET,
    WALL_FILLING_GET_ONE,
    WALL_FILLING_UPDATE
} from "../actions/wallFillingActions";
import {WALL_FILLING_GET_ONE_SET, WALL_FILLING_GET_SET} from "../mutations/wallFillingMutations";
import WallFillingRepository from "../../repositories/WallFillingRepository";

const state = {
    wallFillings: [],
    wallFilling: []
}

const getters = {
    wallFillings(state) {
        return state.wallFillings;
    },
    wallFilling(state) {
        return state.wallFilling;
    },
}

const actions = {
    [WALL_FILLING_GET](context) {
        return new Promise((resolve, reject) => {
            WallFillingRepository.get()
                .then((result) => {
                    context.commit(WALL_FILLING_GET_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [WALL_FILLING_GET_ONE](context, id) {
        return new Promise((resolve, reject) => {
            WallFillingRepository.getOne(id)
                .then((result) => {
                    context.commit(WALL_FILLING_GET_ONE_SET, result.data.data);
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                });
        });
    },
    [WALL_FILLING_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            WallFillingRepository.create(payload)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [WALL_FILLING_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            WallFillingRepository.update(payload.id, payload.data)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    },
    [WALL_FILLING_DELETE](context, id) {
        return new Promise((resolve, reject) => {
            WallFillingRepository.deleteOne(id)
                .then((result) => {
                    resolve(result);
                })
                .catch((result) => {
                    reject(result);
                })
        });
    }
};

const mutations = {
    [WALL_FILLING_GET_SET](state, fillings) {
        state.wallFillings = fillings;
    },
    [WALL_FILLING_GET_ONE_SET](state, filling) {
        state.wallFilling = filling;
    }
}

export default {state, actions, mutations, getters};
