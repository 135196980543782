import config from "../config";
const notificationsConfig = config.notifications;

export const getTypes = () => {
	return notificationsConfig.types;
}
export const getTimeout = () => {
	return notificationsConfig.timeout;
}

export default { getTypes, getTimeout };