import RepositoryService, {update} from "../services/RepositoryService";
const resource = 'auth';

export const check = () => {
	return RepositoryService.getRaw(`${resource}/me`);
}

export const login = (credentials) => {
	return RepositoryService.post(`${resource}/login`, {
		email: credentials.email,
		password: credentials.password,
		remember: credentials.remember
	});
}

export const logout = () => {
	return RepositoryService.post(`${resource}/logout`, );
}

export const passwordForgot = (data) => {
	return RepositoryService.post(`${resource}/password-forget`, {
		email: data.email
	});
}

export const passwordReset = (data) => {
	return RepositoryService.post(`${resource}/password-reset`, {
		token: data.token,
		email: data.email,
		password: data.password,
		password_confirmation: data.password_confirmation
	});
}

export const register = (data) => {
	return RepositoryService.post(`register`, data);
}

export const registerConfirm = (userId) => {
	return RepositoryService.get(`register/confirm/${userId}`);
}

export const profileEdit = (data) => {
	return RepositoryService.update(`${resource}/me`, false, data);
}

export default { check, login, logout, passwordForgot, passwordReset, register, registerConfirm, profileEdit }