export const currencies = {
	defaultId: 1,
	currencies: [
		{
			id: 1,
			rate: 0,
			short: 'zł',
			short2: 'PLN',
			long: 'Polski Złoty'
		}
	]
}