import {
	CUSTOMER_DOOR_MUNTINS_PLACE,
	CUSTOMER_DOOR_LEAF_DIMENSION,
	CUSTOMER_DOOR_SYSTEMS_GET,
	CUSTOMER_DOOR_SYSTEMS_GET_ONE,
	CUSTOMER_DOOR_SYSTEMS_GET_SORTED,
} from "../../actions/customer/customerDoorActions";
import {
	CUSTOMER_DOOR_MUNTINS_PLACE_SET,
	CUSTOMER_DOOR_LEAF_DIMENSION_SET,
	CUSTOMER_DOOR_SYSTEMS_GET_ONE_SET,
	CUSTOMER_DOOR_SYSTEMS_GET_SET
} from "../../mutations/customer/customerDoorMutations";
import CustomerDoorRepository from "../../../repositories/customer/CustomerDoorRepository";

const state = {
	customerDoorSystems: [],
	customerDoorSystem: {},
	customerDoorLeafDimension: {},
	customerDoorMuntinsPlace: {}
}

const getters = {
	customerDoorSystems(state) {
		return state.customerDoorSystems;
	},
	customerDoorSystem(state) {
		return state.customerDoorSystem;
	},
	customerDoorLeafDimension(state) {
		return state.customerDoorLeafDimension;
	},
	customerDoorMuntinsPlace(state) {
		return state.customerDoorMuntinsPlace;
	},
}

const actions = {
	[CUSTOMER_DOOR_SYSTEMS_GET](context) {
		return new Promise((resolve, reject) => {
			CustomerDoorRepository.get()
				.then((result) => {
					context.commit(CUSTOMER_DOOR_SYSTEMS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_DOOR_SYSTEMS_GET_SORTED](context, column) {
		return new Promise((resolve, reject) => {
			CustomerDoorRepository.getSorted(column)
				.then((result) => {
					context.commit(CUSTOMER_DOOR_SYSTEMS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_DOOR_SYSTEMS_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			CustomerDoorRepository.getOne(id)
				.then((result) => {
					context.commit(CUSTOMER_DOOR_SYSTEMS_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_DOOR_LEAF_DIMENSION](context, data) {
		return new Promise((resolve, reject) => {
			CustomerDoorRepository.getDoorLeafDimension({
				door_id: data.doorId,
				hole_width: data.holeWidth,
				hole_height: data.holeHeight,
				frame_id: data.frameId,
				is_double_door_leaf: data.doubleDoor,
				muntin_is_construction: null,
				floor_layer_thickness: data.floorLayerThickness,
				door_leaf_width: data.doorLeafWidth? data.doorLeafWidth : null,
				door_leaf_passive_width: data.doorLeafPassiveWidth? data.doorLeafPassiveWidth : null,
				door_height: data.doorHeight? data.doorHeight : null
			})
				.then((result) => {
					context.commit(CUSTOMER_DOOR_LEAF_DIMENSION_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},

	[CUSTOMER_DOOR_MUNTINS_PLACE](context, data) {
		return new Promise((resolve, reject) => {
			CustomerDoorRepository.getDoorMuntinsPlace({
				door_id: data.doorId,
				frame_id: data.frameId,
				is_double_door_leaf: data.doubleDoor ? 1 : 0,
				muntin_is_construction: data.muntinIsConstruction ? 1 : 0,
				floor_layer_thickness: data.floorLayerThickness,
				door_leaf_width: data.doorLeafWidth ? data.doorLeafWidth : null,
				door_leaf_passive_width: data.doorLeafPassiveWidth ? data.doorLeafPassiveWidth : null,
				door_height: data.doorHeight ? data.doorHeight : null,
				muntin_vertical_count: data.muntinsVertical ? data.muntinsVertical : 0,
				muntin_horizontal_count: data.muntinsHorizontal ? data.muntinsHorizontal : 0,
				muntin_passive_vertical_count: data.muntinsPassiveVertical ? data.muntinsPassiveVertical : 0,
				muntin_passive_horizontal_count: data.muntinsPassiveHorizontal ? data.muntinsPassiveHorizontal : 0
			})
				.then((result) => {
					context.commit(CUSTOMER_DOOR_MUNTINS_PLACE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
};

const mutations = {
	[CUSTOMER_DOOR_SYSTEMS_GET_SET](state, doorSystems) {
		state.customerDoorSystems = doorSystems;
	},
	[CUSTOMER_DOOR_SYSTEMS_GET_ONE_SET](state, doorSystem) {
		state.customerDoorSystem = doorSystem;
	},
	[CUSTOMER_DOOR_LEAF_DIMENSION_SET](state, doorLeafDimension) {
		state.customerDoorLeafDimension = doorLeafDimension;
	},
	[CUSTOMER_DOOR_MUNTINS_PLACE_SET](state, doorMuntinsPlace) {
		state.customerDoorMuntinsPlace = doorMuntinsPlace;
	}
}

export default { state, actions, mutations, getters };
