import RepositoryService from "../../services/RepositoryService";
const resource = 'customer/mobile-walls';

export const get = () => {
	return RepositoryService.get(`${resource}/show`);
}

export const getMobileWallDimension = (payload) => {
	return RepositoryService.post(`${resource}/calc/mobile-wall-dimension`, payload, true);
}

export const getMobileWallModulesHeight = (payload) => {
	return RepositoryService.getRaw(`${resource}/calc/height`, payload);
}

export default { get, getMobileWallDimension, getMobileWallModulesHeight }