import {ACOUSTIC_GET_BY_TYPE_SET, ACOUSTIC_GET_ONE_SET, ACOUSTIC_GET_SET} from "../mutations/acousticMutations";
import {
	ACOUSTIC_CREATE,
	ACOUSTIC_DELETE,
	ACOUSTIC_GET, ACOUSTIC_GET_BY_TYPE,
	ACOUSTIC_GET_ONE,
	ACOUSTIC_UPDATE
} from "../actions/acousticActions";
import AcousticRepository from "../../repositories/AcousticRepository";

const state = {
	acoustics: [],
	acoustic: [],
	acousticsByType: []
}

const getters = {
	acoustics(state) {
		return state.acoustics;
	},
	acoustic(state) {
		return state.acoustic;
	},
	acousticsByType(state) {
		return state.acousticsByType;
	},
}

const actions = {
	[ACOUSTIC_GET](context) {
		return new Promise((resolve, reject) => {
            AcousticRepository.get()
				.then((result) => {
					context.commit(ACOUSTIC_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[ACOUSTIC_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
            AcousticRepository.getOne(id)
				.then((result) => {
					context.commit(ACOUSTIC_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[ACOUSTIC_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
            AcousticRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[ACOUSTIC_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			AcousticRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[ACOUSTIC_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			AcousticRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[ACOUSTIC_GET_BY_TYPE](context, type) {
		return new Promise((resolve, reject) => {
			AcousticRepository.getByType(type)
				.then((result) => {
					context.commit(ACOUSTIC_GET_BY_TYPE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[ACOUSTIC_GET_SET](state, acoustics) {
		state.acoustics = acoustics;
	},
	[ACOUSTIC_GET_ONE_SET](state, acoustic) {
		state.acoustic = acoustic;
	},
	[ACOUSTIC_GET_BY_TYPE_SET](state, acoustics) {
		state.acousticsByType = acoustics;
	}
}

export default { state, actions, mutations, getters };
