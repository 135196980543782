import RepositoryService from "../../services/RepositoryService";
const resource = 'admin/orders';

export const get = () => {
	return RepositoryService.get(resource);
}

export const getOne = (id) => {
	return RepositoryService.getOne(resource, id);
}

export default { get, getOne }