import RepositoryService from "../services/RepositoryService";
const resource = 'admin/price-file';

export const get = () => {
	return RepositoryService.get(`${resource}`);
}

export const create = (payload) => {
	return RepositoryService.post(`${resource}`, payload);
}

export const getTypes = (payload) => {
	return RepositoryService.get(`${resource}/types`, payload);
}

export const importAgain = (id) => {
	return RepositoryService.get(`${resource}/${id}/import-again`);
}

export default { get, create, getTypes, importAgain }