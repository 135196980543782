import {
	DOOR_HINGE_CREATE,
	DOOR_HINGE_DELETE,
	DOOR_HINGE_GET,
	DOOR_HINGE_GET_ONE,
	DOOR_HINGE_UPDATE
} from "../actions/doorHingeActions";
import {DOOR_HINGE_GET_ONE_SET, DOOR_HINGE_GET_SET} from "../mutations/doorHingeMutations";
import DoorHingeRepository from "../../repositories/DoorHingeRepository";

const state = {
	doorHinges: [],
	doorHinge: []
}

const getters = {
	doorHinges(state) {
		return state.doorHinges;
	},
	doorHinge(state) {
		return state.doorHinge;
	},
}

const actions = {
	[DOOR_HINGE_GET](context) {
		return new Promise((resolve, reject) => {
			DoorHingeRepository.get()
				.then((result) => {
					context.commit(DOOR_HINGE_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_HINGE_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			DoorHingeRepository.getOne(id)
				.then((result) => {
					context.commit(DOOR_HINGE_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_HINGE_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorHingeRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_HINGE_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorHingeRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_HINGE_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			DoorHingeRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[DOOR_HINGE_GET_SET](state, groups) {
		state.doorHinges = groups;
	},
	[DOOR_HINGE_GET_ONE_SET](state, group) {
		state.doorHinge = group;
	}
}

export default { state, actions, mutations, getters };
