import {
	CUSTOMER_APP_SETTINGS_CONTACT_GET,
	CUSTOMER_APP_SETTINGS_INSTRUCTIONS_GET
} from "../../actions/customer/customerAppSettingsActions";
import {
	CUSTOMER_APP_SETTINGS_CONTACT_SET,
	CUSTOMER_APP_SETTINGS_INSTRUCTIONS_SET
} from "../../mutations/customer/customerAppSettingsMutations";
import CustomerAppSettingsRepository from "../../../repositories/customer/CustomerAppSettingsRepository";

const state = {
	customerAppSettingsContact: [],
	customerAppSettingsInstructions: [],
}

const getters = {
	customerAppSettingsContact(state) {
		return state.customerAppSettingsContact;
	},
	customerAppSettingsInstructions(state) {
		return state.customerAppSettingsInstructions;
	}
}

const actions = {
	[CUSTOMER_APP_SETTINGS_CONTACT_GET](context) {
		return new Promise((resolve, reject) => {
			CustomerAppSettingsRepository.getContact()
				.then((result) => {
					context.commit(CUSTOMER_APP_SETTINGS_CONTACT_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_APP_SETTINGS_INSTRUCTIONS_GET](context) {
		return new Promise((resolve, reject) => {
			CustomerAppSettingsRepository.getInstructions()
				.then((result) => {
					context.commit(CUSTOMER_APP_SETTINGS_INSTRUCTIONS_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	}
};

const mutations = {
	[CUSTOMER_APP_SETTINGS_CONTACT_SET](state, contact) {
		state.customerAppSettingsContact = contact;
	},
	[CUSTOMER_APP_SETTINGS_INSTRUCTIONS_SET](state, instructions) {
		state.customerAppSettingsInstructions = instructions;
	}
}

export default { state, actions, mutations, getters };
