import {
	DOOR_FRAME_CREATE,
	DOOR_FRAME_DELETE,
	DOOR_FRAME_GET,
	DOOR_FRAME_GET_ONE,
	DOOR_FRAME_UPDATE
} from "../actions/doorFrameActions";
import {DOOR_FRAME_GET_ONE_SET, DOOR_FRAME_GET_SET} from "../mutations/doorFrameMutations";
import DoorFrameRepository from "../../repositories/DoorFrameRepository";

const state = {
	doorFrames: [],
	doorFrame: []
}

const getters = {
	doorFrames(state) {
		return state.doorFrames;
	},
	doorFrame(state) {
		return state.doorFrame;
	},
}

const actions = {
	[DOOR_FRAME_GET](context) {
		return new Promise((resolve, reject) => {
			DoorFrameRepository.get()
				.then((result) => {
					context.commit(DOOR_FRAME_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_FRAME_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			DoorFrameRepository.getOne(id)
				.then((result) => {
					context.commit(DOOR_FRAME_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_FRAME_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorFrameRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_FRAME_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorFrameRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_FRAME_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			DoorFrameRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[DOOR_FRAME_GET_SET](state, groups) {
		state.doorFrames = groups;
	},
	[DOOR_FRAME_GET_ONE_SET](state, group) {
		state.doorFrame = group;
	}
}

export default { state, actions, mutations, getters };
