const orderRoutes = [
	{
		name: 'order.layout.column.one',
		path: '',
		component: () => import('../views/layouts/columns/TheColumnOne.vue'),
		children: [
			{
				name: 'order.product',
				path: '',
				component: () => import('../views/pages/order/OrderProduct.vue')
			},
			{
				name: 'order.door',
				path: 'door',
				component: () => import('../views/pages/order/door/OrderDoor.vue')
			},
			{
				name: 'order.mobileWall',
				path: 'mobile-wall',
				component: () => import('../views/pages/order/mobile_wall/OrderMobileWall.vue')
			},
			{
				name: 'order.wall',
				path: 'wall',
				component: () => import('../views/pages/order/wall/OrderWall.vue')
			},
			{
				name: 'order.wallWithDoor',
				path: 'wall-with-door',
				component: () => import('../views/pages/order/wall_with_door/OrderWall.vue')
			},
			{
				name: 'order.wallWithDoorStep2',
				path: 'wall-with-door-step-2/:wallSystem',
				component: () => import('../views/pages/order/wall_with_door/OrderDoor.vue')
			},
		]
	},
	{
		name: 'order.layout.columns.three',
		path: '',
		component: () => import('../views/layouts/columns/TheColumnsThree.vue'),
		children: [
			{
				name: 'order.door.form',
				path: 'door/:system',
				components: {
					default: () => import('../views/pages/order/door/preview/OrderDoorPreview.vue'),
					colLeft: () => import('../views/pages/order/door/form/OrderDoorForm.vue'),
					colRight: () => import('../views/pages/order/door/summary/OrderDoorSummary.vue'),
				}
			},
			{
				name: 'order.door.preview',
				path: 'door-preview/:id',
				components: {
					default: () => import('../views/pages/order/door/preview/OrderDoorPreviewPreview.vue'),
					colLeft: () => import('../views/pages/order/door/form/OrderDoorFormPreview.vue'),
					colRight: () => import('../views/pages/order/door/summary/OrderDoorSummaryPreview.vue'),
				}
			},
			{
				name: 'order.mobileWall.form',
				path: 'mobile-wall/:geometry',
				components: {
					default: () => import('../views/pages/order/mobile_wall/preview/OrderMobileWallPreview.vue'),
					colLeft: () => import('../views/pages/order/mobile_wall/form/OrderMobileWallForm.vue'),
					colRight: () => import('../views/pages/order/mobile_wall/summary/OrderMobileWallSummary.vue'),
				}
			},
			{
				name: 'order.mobileWall.preview',
				path: 'mobile-wall-preview/:id',
				components: {
					default: () => import('../views/pages/order/mobile_wall/preview/OrderMobileWallPreviewPreview.vue'),
					colLeft: () => import('../views/pages/order/mobile_wall/form/OrderMobileWallFormPreview.vue'),
					colRight: () => import('../views/pages/order/mobile_wall/summary/OrderMobileWallSummaryPreview.vue'),
				}
			},
			{
				name: 'order.wall.form',
				path: 'wall/:system',
				components: {
					default: () => import('../views/pages/order/wall/preview/OrderWallPreview.vue'),
					colLeft: () => import('../views/pages/order/wall/form/OrderWallForm.vue'),
					colRight: () => import('../views/pages/order/wall/summary/OrderWallSummary.vue'),
				}
			},
			{
				name: 'order.wall.preview',
				path: 'wall-preview/:id',
				components: {
					default: () => import('../views/pages/order/wall/preview/OrderWallPreviewPreview.vue'),
					colLeft: () => import('../views/pages/order/wall/form/OrderWallFormPreview.vue'),
					colRight: () => import('../views/pages/order/wall/summary/OrderWallSummaryPreview.vue'),
				}
			},
			{
				name: 'order.wallWithDoor.form',
				path: 'wall-with-door/:wallSystem/:doorSystem',
				components: {
					default: () => import('../views/pages/order/wall_with_door/preview/OrderWallWithDoorPreview.vue'),
					colLeft: () => import('../views/pages/order/wall_with_door/form/OrderWallWithDoorForm.vue'),
					colRight: () => import('../views/pages/order/wall_with_door/summary/OrderWallWithDoorSummary.vue'),
				}
			},
			{
				name: 'order.wallWithDoor.preview',
				path: 'wall-with-door-preview/:id',
				components: {
					default: () => import('../views/pages/order/wall_with_door/preview/OrderWallWithDoorPreviewPreview.vue'),
					colLeft: () => import('../views/pages/order/wall_with_door/form/OrderWallWithDoorFormPreview.vue'),
					colRight: () => import('../views/pages/order/wall_with_door/summary/OrderWallWithDoorSummaryPreview.vue'),
				}
			},
		]
	},
	{
		name: 'order.door.orderAgain',
		path: 'door-order-again/:id',
		component: () => import('../views/pages/order/door/OrderDoorOrderAgain.vue')
	},
	{
		name: 'order.wall.orderAgain',
		path: 'wall-order-again/:id',
		component: () => import('../views/pages/order/wall/OrderWallOrderAgain.vue')
	},
	{
		name: 'order.mobileWall.orderAgain',
		path: 'mobile-wall-order-again/:id',
		component: () => import('../views/pages/order/mobile_wall/OrderMobileWallOrderAgain.vue')
	},
	{
		name: 'order.wallWithDoor.orderAgain',
		path: 'wall-with-door-order-again/:id',
		component: () => import('../views/pages/order/wall_with_door/OrderWallWithDoorOrderAgain.vue')
	},
];

export default orderRoutes;