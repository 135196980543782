import store from "../store";
import { AUTH_CHECK } from "../store/actions/authActions";
import LocaleService from "../services/LocaleService";
import RouterService from "../services/RouterService";
import RoutesService from "../services/RoutesService";

export const AuthFailedMiddleware = ({to, next}) => {
	store.dispatch(AUTH_CHECK)
		.then((response) => {
			const locale = to.params.locale? to.params.locale : LocaleService.getLocale();
			return RouterService.push({ name: RoutesService.defaultRoute(), params: { locale: locale } });
		})
		.catch((response) => {
			return next();
		});
}

export default AuthFailedMiddleware;