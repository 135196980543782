import store from "../store";
import {SANCTUM_CSRF} from "../store/actions/sanctumActions";
import {useCookies} from "@vueuse/integrations/useCookies";

export const SanctumMiddleware = ({to, next}) => {
	const { get } = useCookies();
	const csrfToken = get('XSRF-TOKEN');
	if (csrfToken) {
		return next();
	}

	store.dispatch(SANCTUM_CSRF)
		.finally(() => {
			return next();
		});
}

export default SanctumMiddleware;