import RepositoryService from "../../services/RepositoryService";
const resource = 'customer/colors';

export const get = () => {
	return RepositoryService.getRaw(`${resource}`);
}

export const getPalette = (palette) => {
	let palletArray = [];
	if (!Array.isArray(palette)) {
		palletArray.push(palette);
	} else {
		palletArray = palette;
	}

	const paletteParams = palletArray.reduce((result, element, i) => {
		result[`filter[color_palette][${i}]`] = element;
		return result;
	}, {});

	return RepositoryService.getRaw(`${resource}`, paletteParams);
}

export default { get, getPalette }
