export const locales = [
	{
		id: 'pl',
		slug: 'pl',
		default: true,
		name: {
			pl: 'Polski',
			en: 'Polish'
		}
	},
	{
		id: 'en',
		slug: 'en',
		default: false,
		name: {
			pl: 'Angielski',
			en: 'English'
		}
	}
];