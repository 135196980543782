import RepositoryService from "../services/RepositoryService";
const resource = 'admin/settings/wall';

export const get = () => {
	return RepositoryService.get(`${resource}`);
}

export const update = (payload) => {
	return RepositoryService.put(`${resource}`, payload);
}

export default { get, update }