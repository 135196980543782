import ContractorGroupRepository, {create, deleteOne} from "../../repositories/ContractorGroupRepository";
import {
	CONTRACTOR_GROUP_CREATE, CONTRACTOR_GROUP_DELETE,
	CONTRACTOR_GROUP_GET,
	CONTRACTOR_GROUP_GET_ONE, CONTRACTOR_GROUP_UPDATE
} from "../actions/contractorGroupActions";
import {CONTRACTOR_GROUP_GET_ONE_SET, CONTRACTOR_GROUP_GET_SET} from "../mutations/contractorGroupMutations";

const state = {
	contractorGroups: [],
	contractorGroup: []
}

const getters = {
	contractorGroups(state) {
		return state.contractorGroups;
	},
	contractorGroup(state) {
		return state.contractorGroup;
	},
}

const actions = {
	[CONTRACTOR_GROUP_GET](context) {
		return new Promise((resolve, reject) => {
			ContractorGroupRepository.get()
				.then((result) => {
					context.commit(CONTRACTOR_GROUP_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CONTRACTOR_GROUP_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			ContractorGroupRepository.getOne(id)
				.then((result) => {
					context.commit(CONTRACTOR_GROUP_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CONTRACTOR_GROUP_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			ContractorGroupRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[CONTRACTOR_GROUP_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			ContractorGroupRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[CONTRACTOR_GROUP_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			ContractorGroupRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[CONTRACTOR_GROUP_GET_SET](state, groups) {
		state.contractorGroups = groups;
	},
	[CONTRACTOR_GROUP_GET_ONE_SET](state, group) {
		state.contractorGroup = group;
	}
}

export default { state, actions, mutations, getters };
