import router from "../routes";
import LocaleService from "./LocaleService";

let filterInitQueryUrl = null;

export const currentRoute = () => {
	return router.currentRoute.value;
}

export const getLayout = () => {
	const route = currentRoute();
	let layout = 'default-layout';
	if (route.meta && route.meta.layout) {
		layout = `${route.meta.layout}-layout`;
	}
	return (_.kebabCase(layout));
}

export const push = (to) => {
	if (typeof to == 'object') {
		const toDefault = {
			params: {
				locale: LocaleService.getLocale()
			},
			replace: true
		};
		to = {...toDefault, ...to};
	}
	return router.push(to)
		.then(() => { })
		.catch(() => { });
}

export const go = (delta) => {
	router.go(delta);
}

export const getQuery = () => {
	return window.location.search;
}

export const getByKey = (key, defaultValue = null) => {
	const route = currentRoute();

	if (undefined === route.query[key]) {
		return defaultValue;
	}

	return route.query[key];
}

export const getParam = (key = null, defaultValue = null) => {
	const route = currentRoute();
	if (!key) {
		return route.params;
	}

	if (undefined === route.params[key]) {
		return defaultValue;
	}

	return route.params[key];
}

export const changeQuery = (query = {}, exclude = []) => {
	const route = currentRoute();
	let newQuery = {...route.query, ...query};

	exclude.forEach((toExclude) => {
		delete newQuery[toExclude];
	});

	router.push({path: route.path, query: newQuery})
		.catch(() => {
		});
}

export const objectToQueryString = (query = {}) => {
	return '?' + Object.keys(query).map(key => {
		if ('object' === typeof query[key]) {
			return query[key].map(param => `${key}[]=${param}`).join('&');
		}

		return `${key}=${query[key]}`;
	}).join('&');
}

export const saveFilterInitQueryUrl = () => {
	filterInitQueryUrl = getQuery();
}

export const getFilterInitQueryUrl = () => {
	return filterInitQueryUrl;
}


export default {
	currentRoute, getLayout, push, go,
	getQuery, getByKey, getParam, changeQuery,
	objectToQueryString, saveFilterInitQueryUrl, getFilterInitQueryUrl
};
