import {
	DOOR_FILLING_CREATE,
	DOOR_FILLING_DELETE,
	DOOR_FILLING_GET,
	DOOR_FILLING_GET_ONE,
	DOOR_FILLING_UPDATE
} from "../actions/doorFillingActions";
import {DOOR_FILLING_GET_ONE_SET, DOOR_FILLING_GET_SET} from "../mutations/doorFillingMutations";
import DoorFillingRepository from "../../repositories/DoorFillingRepository";

const state = {
	doorFillings: [],
	doorFilling: []
}

const getters = {
	doorFillings(state) {
		return state.doorFillings;
	},
	doorFilling(state) {
		return state.doorFilling;
	},
}

const actions = {
	[DOOR_FILLING_GET](context) {
		return new Promise((resolve, reject) => {
			DoorFillingRepository.get()
				.then((result) => {
					context.commit(DOOR_FILLING_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_FILLING_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			DoorFillingRepository.getOne(id)
				.then((result) => {
					context.commit(DOOR_FILLING_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[DOOR_FILLING_CREATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorFillingRepository.create(payload)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_FILLING_UPDATE](context, payload) {
		return new Promise((resolve, reject) => {
			DoorFillingRepository.update(payload.id, payload.data)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	},
	[DOOR_FILLING_DELETE](context, id) {
		return new Promise((resolve, reject) => {
			DoorFillingRepository.deleteOne(id)
				.then((result) => {
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				})
		});
	}
};

const mutations = {
	[DOOR_FILLING_GET_SET](state, groups) {
		state.doorFillings = groups;
	},
	[DOOR_FILLING_GET_ONE_SET](state, group) {
		state.doorFilling = group;
	}
}

export default { state, actions, mutations, getters };
