import {
	CUSTOMER_WALL_DIMENSION,
	CUSTOMER_WALL_DIMENSION_CHECK,
	CUSTOMER_WALL_SYSTEMS_GET,
	CUSTOMER_WALL_SYSTEMS_GET_ONE,
	CUSTOMER_WALL_SYSTEMS_GET_SORTED,
} from "../../actions/customer/customerWallActions";
import {
	CUSTOMER_WALL_DIMENSION_SET,
	CUSTOMER_WALL_DIMENSION_CHECK_SET,
	CUSTOMER_WALL_SYSTEMS_GET_ONE_SET,
	CUSTOMER_WALL_SYSTEMS_GET_SET,
} from "../../mutations/customer/customerWallMutations";
import CustomerWallRepository from "../../../repositories/customer/CustomerWallRepository";

const state = {
	customerWallSystems: [],
	customerWallSystem: {},
	customerWallDimension: {},
	customerWallDimensionCheck: {},
}

const getters = {
	customerWallSystems(state) {
		return state.customerWallSystems;
	},
	customerWallSystem(state) {
		return state.customerWallSystem;
	},
	customerWallDimension(state) {
		return state.customerWallDimension;
	},
	customerWallDimensionCheck(state) {
		return state.customerWallDimensionCheck;
	},
}

const actions = {
	[CUSTOMER_WALL_SYSTEMS_GET](context) {
		return new Promise((resolve, reject) => {
			CustomerWallRepository.get()
				.then((result) => {
					context.commit(CUSTOMER_WALL_SYSTEMS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_WALL_SYSTEMS_GET_SORTED](context, column) {
		return new Promise((resolve, reject) => {
			CustomerWallRepository.getSorted(column)
				.then((result) => {
					context.commit(CUSTOMER_WALL_SYSTEMS_GET_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_WALL_SYSTEMS_GET_ONE](context, id) {
		return new Promise((resolve, reject) => {
			CustomerWallRepository.getOne(id)
				.then((result) => {
					context.commit(CUSTOMER_WALL_SYSTEMS_GET_ONE_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_WALL_DIMENSION](context, data) {
		return new Promise((resolve, reject) => {
			CustomerWallRepository.getWallDimension(data)
				.then((result) => {
					context.commit(CUSTOMER_WALL_DIMENSION_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
	[CUSTOMER_WALL_DIMENSION_CHECK](context, data) {
		return new Promise((resolve, reject) => {
			CustomerWallRepository.getWallDimensionCheck(data)
				.then((result) => {
					context.commit(CUSTOMER_WALL_DIMENSION_CHECK_SET, result.data.data);
					resolve(result);
				})
				.catch((result) => {
					reject(result);
				});
		});
	},
};

const mutations = {
	[CUSTOMER_WALL_SYSTEMS_GET_SET](state, wallSystems) {
		state.customerWallSystems = wallSystems;
	},
	[CUSTOMER_WALL_SYSTEMS_GET_ONE_SET](state, wallSystem) {
		state.customerWallSystem = wallSystem;
	},
	[CUSTOMER_WALL_DIMENSION_SET](state, wallDimension) {
		state.customerWallDimension = wallDimension;
	},
	[CUSTOMER_WALL_DIMENSION_CHECK_SET](state, wallDimension) {
		state.customerWallDimensionCheck = wallDimension;
	},
}

export default { state, actions, mutations, getters };
