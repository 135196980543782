import RepositoryService from "../../services/RepositoryService";
const resource = 'customer/walls';

export const get = () => {
	return RepositoryService.get(`${resource}`);
}

export const getSorted = (column) => {
	return RepositoryService.getRaw(`${resource}?sort=${column}`);
}

export const getOne = (id) => {
	return RepositoryService.getOne(`${resource}`, id);
}

export const getWallDimension = (payload) => {
	return RepositoryService.get(`${resource}/calc/wall-dimension`, payload);
}
export const getWallDimensionCheck = (payload) => {
	return RepositoryService.post(`${resource}/calc/wall-dimension-check`, payload, true);
}

export default { get, getSorted, getOne, getWallDimension, getWallDimensionCheck }