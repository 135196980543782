import RepositoryService from "../../services/RepositoryService";
const resource = 'customer/settings';

export const getContact = () => {
	return RepositoryService.get(`${resource}/contact`);
}

export const getInstructions = () => {
	return RepositoryService.get(`${resource}/instructions`);
}

export default { getContact, getInstructions }