import config from "../config";
import store from "../store";

const hasRole = (role, user) => {
	if (!role || !user) {
		return false;
	}

	const userRoleId = (typeof user === 'object'? user.role_id : user);

	return '' + role === '' + userRoleId;
}

const getCurrentUser = () => {
	return store.getters.user;
}

export const getRoles = () => {
	return config.roles;
}

export const adminRole = () => {
	return getRoles()['admin'];
}

export const customerRole = () => {
	return getRoles()['customer'];
}

export const staffRole = () => {
	return getRoles()['staff'];
}

export const isAdmin = (user = null) => {
	if (!user) {
		user = getCurrentUser();
	}
	return hasRole(adminRole(), user);
}

export const isCustomer = (user = null) => {
	if (!user) {
		user = getCurrentUser();
	}
	return hasRole(customerRole(), user);
}

export const isStaff = (user = null) => {
	if (!user) {
		user = getCurrentUser();
	}
	return hasRole(staffRole(), user);
}

export default { getRoles, adminRole, customerRole, staffRole, isAdmin, isCustomer, isStaff }